import {
    Button,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Table,
    TableContainer,
    ModalBody,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useDisclosure,
    Input,
    HStack,
    useToast,
    Spinner,
    Select as ChakraSelect
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import Select from "react-select";
import {
    AdminCreateCoupons,
    AdminGetAllCoupons,
    DeleteCoupon,
} from "../../../utils/api";
import Empty from "../../../components/Empty";
import CouponCard from "../components/CouponCard";
const Coupon = () => {
    const addCouponPriceRef = useRef();
    const addServerRef = useRef();
    const { AdminToken } = useContext(AppContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [pageNo, setPageNo] = useState(1);
    const [limitNo, setLimitNo] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const toast = useToast();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [couponId, setCouponId] = useState('');
    const [searchData, setSearchData] = useState([]);
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [searched, setSearched] = useState({});
    const handleCreateCoupon = (event) => {
        event.preventDefault();
        let couponData = {};
        couponData.couponPrice = addCouponPriceRef.current.value || 0;
        if (!couponData.couponPrice) {
            toast({
                title: `Please Enter the price`,
                status: "warning",
                isClosable: true,
                duration: 3000,
            });
        } else {
            AdminCreateCoupons(couponData, AdminToken)
                .then((res) => {
                    toast({
                        title: `Coupon Created Successfully`,
                        status: "success",
                        isClosable: true,
                        duration: 3000,
                    });
                    data.unshift(res.data.data);
                    setData([...data]);
                    console.log(res.data.data, 'my resposne');

                    onClose();
                })
                .catch((err) => {
                    toast({
                        title: err.response?.data?.message
                            ? err.response?.data.message
                            : err.message,
                        status: "error",
                        isClosable: true,
                        duration: 3000,
                    });
                });
        }
    };
    useEffect(() => {
        setLoading(false);
        AdminGetAllCoupons(AdminToken, pageNo, limitNo)
            .then((res) => {
                setLoading(true);
                setData(res.data.coupons);
                setTotalPages(res.data.total_pages);
            })
            .catch((err) => {
                setLoading(true);
                console.log(err, "error message");
            });
    }, [limitNo, pageNo]);
    const OpenBlockModal = (couponId, el) => {
        setCouponId(couponId);
        setDeleteModal(true);
    };
    const CloseBlockModal = () => {
        setDeleteModal(false);
    };
    const handleDelete = () => {
        DeleteCoupon(couponId, AdminToken)
            .then((res) => {
                toast({
                    title: res.data.message,
                    isClosable: true,
                    status: 'warning'
                })
                let currentData = data;
                currentData = currentData.filter((el) => el._id !== couponId);
                if (currentData.length === 0) {
                    setPageNo((pre) => { if (pageNo !== 1) { return pre - 1 } return pre });
                }
                setData([...currentData]);
                let current2 = searchData;
                // console.log(current2.length, 'debbugging');
                current2 = current2.filter((el) => el.value != couponId);
                // console.log(current2.length, 'debbugging');
                setSearchData([...current2]);
                setSearched({});
                CloseBlockModal();
            })
            .catch((err) => {
                toast({
                    title: err.response?.data?.message
                        ? err.response?.data.message
                        : err.message,
                    isClosable: true,
                    status: 'error'
                })
            });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
    };
    return (
        <VStack
            gap={"1rem"}
            bg={"white"}
            align={"end"}
            justify={"start"}
            height={"100%"}
            minH={"100vh"}
            w={"79%"}
            p={"1.5rem"}
        >
            <Button
                _hover={{
                    bgColor: "white",
                    color: "rgb(220, 20, 60)",
                }}
                onClick={onOpen}
                color={"white"}
                bgColor={"rgb(220, 20, 60)"}
                border={"2.4px solid rgb(220, 20, 60) !important"}
            >
                Add Coupon
            </Button>
            {data.length && loading ? (
                <TableContainer
                    boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                    w={"100%"}
                >
                    <Table
                        variant="simple"
                        colorScheme="facebook"
                        bgColor="white"
                        size={["md", "lg", "lg"]}
                        overflowX={"auto"}
                        boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
                    >
                        <Thead
                            background={"rgb(229 60 94)"}
                            color={"white !important"}
                        >
                            <Tr>
                                <Th color={"white"}>Coupon Code</Th>
                                <Th color={"white"}>Coupon Price</Th>
                                <Th color={"white"}>Status</Th>
                                <Th color={"white"}>User</Th>
                                <Th color={"white"}>Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data?.map((el) => {
                                return (
                                    <CouponCard
                                        OpenBlockModal={OpenBlockModal}
                                        CloseBlockModal={CloseBlockModal}
                                        key={el._id}
                                        id={el._id}
                                        couponCode={el.couponCode}
                                        couponPrice={el.couponPrice}
                                        isExpired={el.isExpired}
                                        email={el?.userId?.email||''}
                                    />
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            ) : (
                <></>
            )}
            {!loading && (
                <VStack justify={"center"} w={"100%"} minH={"50vh"}>
                    <Spinner
                        thickness="5px"
                        speed="0.55s"
                        emptyColor="gray.200"
                        color="red.400"
                        size="xl"
                    />
                </VStack>
            )}

            {totalPages > 1 && (
                <HStack gap={"1rem"} align={"center"}>
                    <ChakraSelect
                        onChange={(e) => {
                            setLimitNo(Number(e.target.value));
                            setPageNo(1);
                        }}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={60}>60</option>
                        <option value={80}>80</option>
                        <option value={100}>100</option>
                    </ChakraSelect>
                    <Button
                        onClick={() => {
                            setPageNo(1);
                        }}
                        padding={"0.5rem 1rem"}
                        width={"fit-content"}
                    >
                        First
                    </Button>
                    <Button
                        onClick={() => {
                            setPageNo((pre) => {
                                if (pre > 1) {
                                    return pre - 1;
                                } else {
                                    return pre;
                                }
                            });
                        }}
                    >
                        {"<<"}
                    </Button>
                    <Text
                        padding={"0.5rem 1rem"}
                        border={"1px solid gray"}
                        borderRadius={"5px"}
                    >
                        {pageNo}
                    </Text>
                    <Text>/</Text>
                    <Text
                        padding={"0.5rem 1rem"}
                        border={"1px solid gray"}
                        borderRadius={"5px"}
                    >
                        {totalPages}
                    </Text>
                    <Button
                        onClick={() => {
                            setPageNo((pre) => {
                                if (pre < totalPages) {
                                    return pre + 1;
                                } else {
                                    return pre;
                                }
                            });
                        }}
                    >
                        {">>"}
                    </Button>
                    <Button
                        onClick={() => {
                            setPageNo(totalPages);
                        }}
                        padding={"0.5rem 1rem"}
                        width={"fit-content"}
                    >
                        Last
                    </Button>
                </HStack>
            )}
            {!data.length && loading ? (
                <Empty title={"No coupons found!!!"} />
            ) : (
                <></>
            )}
            <Modal isOpen={deleteModal} onClose={CloseBlockModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody
                        display={"flex"}
                        justifyContent={"center"}
                        minH={"30vh"}
                        flexDirection={"column"}
                        gap={"1rem"}
                        alignItems={"center"}
                        pb={6}
                    >
                        <Text fontSize={"1.4rem"} fontWeight={"600"}>
                            Are you sure to Delete This coupon!!!
                        </Text>
                        <HStack>
                            <Button
                                onClick={() => {
                                    handleDelete();
                                }}
                                _hover={{
                                    bgColor: "white",
                                    color: "rgb(220, 20, 60)",
                                }}
                                color={"white"}
                                bgColor={"rgb(220, 20, 60)"}
                                border={
                                    "2.4px solid rgb(220, 20, 60) !important"
                                }
                            >
                                Confirm
                            </Button>
                            <Button
                                color={"rgb(220, 20, 60)"}
                                bg={"white"}
                                border={
                                    "2.4px solid rgb(220, 20, 60) !important"
                                }
                                _hover={{
                                    bgColor: "rgb(220, 20, 60)",
                                    color: "white",
                                }}
                                onClick={CloseBlockModal}
                            >
                                Cancel
                            </Button>
                        </HStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {/* Add Coupon Modal  */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody
                        display={"flex"}
                        justifyContent={"center"}
                        minH={"35vh"}
                        gap={"1rem"}
                        alignItems={"center"}
                        pb={6}
                        pt={5}
                    >
                        <form
                            style={{ width: "76.5%" }}
                            onSubmit={handleSubmit}
                        >
                            <VStack p={"1.5rem 0.8rem"} align="flex-start">
                                <Input
                                    type={"number"}
                                    placeholder="price"
                                    ref={addCouponPriceRef}
                                />
                                <Button
                                    _hover={{
                                        color: "rgb(218 52 85)",
                                        background: "white",
                                    }}
                                    type="submit"
                                    mt={"2rem !important"}
                                    bg="rgb(218 52 85)"
                                    color={"white"}
                                    border="1.5px solid rgb(218 52 85) !important"
                                    width="full"
                                    outline={"none"}
                                    onClick={handleCreateCoupon}
                                >
                                    Create Coupon
                                </Button>
                            </VStack>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </VStack>
    );
};

export default Coupon;
