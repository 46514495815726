import { Button, Input, InputGroup, InputRightElement, Td, Textarea, Tr, useToast, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { useState, useRef, useContext, useEffect } from 'react';
import { AppContext } from "../context/AppContext";
import { GiSandsOfTime } from 'react-icons/gi';
import { FiCopy } from "react-icons/fi";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsCheckCircle } from 'react-icons/bs';
import { CancelOtpAPI, GenerateOtpAPI } from "../utils/api";
import styles from './OtpHistoryCard.module.css';
function getInitialTime(backendtime) {
  let currenttime = new Date();
  let currentOffset = currenttime.getTimezoneOffset();
  let ISTOffset = 330;
  let ISTTime = new Date(currenttime.getTime() + (ISTOffset + currentOffset) * 60000);

  let hours = ISTTime.getHours();
  let minutes = ISTTime.getMinutes();
  let seconds = ISTTime.getSeconds();

  let Btime = backendtime.split(" : ")[1].trim().split(":");
  let B_hour = Number(Btime[0]);
  let B_minutes = Number(Btime[1]);
  let B_seconds = Number(Btime[2]);

  if (B_minutes >= 40) {
    B_minutes = 20 - (60 - B_minutes);
    B_hour += 1;
  } else {
    B_minutes += 20;
  }

  let leftminutes = B_hour * 60 + B_minutes - (hours * 60 + minutes);
  let leftseconds = B_seconds - seconds;

  let finalTime = (leftminutes * 60) + leftseconds;

  return finalTime;
}

const formatTime = time => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
const OtpHistoryCard = ({ serviceName, otpMessages, mobile, time, price, status, mobileId, serialNumber, handleOTPCancel, handleOTPMessage, server, businessCode, cancelBtnRequestInProgress, setCancelBtnRequestInProgress }) => {
  const { setBalance, Token, handleLogout } = useContext(AppContext);
  const toast = useToast();
  const [remainingTime, setRemainingTime] = useState(getInitialTime(time));
  const [isLargerThan650] = useMediaQuery("(min-width: 650px)");
  const [showTime, setShowTime] = useState(false);
  const intervalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [resend, setResend] = useState(false);
  const [resendLoading, setResendLoading] = useState(false)
  const requestRef = useRef(false);
  const [currentNumber, setCurrentNumber] = useState({
    serialNumber: "",
    mobileno: "",
    mobileId: "",
  });
  useEffect(() => {
    let myInitialTime = getInitialTime(time);
    if (myInitialTime >= 0 && status == 'pending') {
      setShowTime(true);
      startChecking(false);
    }
  }, [])
  // const serialNumberRef = useRef(null);
  const CopyToClipBoard = () => {
    let numberForCopy = "";
    for (let i = 3; i < mobile.length; i++) {
      numberForCopy += mobile[i];
    }
    if (mobile != "") {
      navigator.clipboard
        .writeText(numberForCopy)
        .then(() => {
          toast({
            status: "info",
            title: `${numberForCopy} number copied`,
            isClosable: true,
            duration: 1000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
          toast({
            status: "error",
            title: `errror in copying number ${mobile}`,
            isClosable: true,
            duration: 1000,
          });
        });
    }
  };
  const handleOTP = (myResend = false) => {
    if (serialNumber != "") {
      GenerateOtpAPI(
        {
          serialNumber,
          mobileId,
          businessCode,
          server,
          resend: myResend
        },
        Token
      )
        .then((res) => {
          requestRef.current = true;
          if (res.data.message == "OTP Number Successfully Generated!") {
            let otpMessage = res.data.otpMessage;
            handleOTPMessage(mobileId, otpMessage);
            clearInterval(intervalRef.current);
            if (intervalRef.current != null) {
              setResendLoading(false);
              toast({
                title: "Otp fetched successfully",
                description: "Now you can use this otp into your service",
                status: "success",
                isClosable: true,
                duration: 2000,
              });
            };

            intervalRef.current = null;
            setShowTime(false);
            setResend(true);
          };
        })
        .catch((err) => {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          setResendLoading(false);
          console.log(err, "error saying");
          setShowTime(false);
          handleLogout(err);
        });
    }
  };
  const startChecking = (myResend = false) => {
    const min = 1000;
    const max = 2000;
    const random_number = Math.floor(Math.random() * (max - min + 1)) + min;
    requestRef.current = true;
    if (intervalRef.current == null) {
      intervalRef.current = setInterval(() => {
        console.log(requestRef.current, 'requests');
        if (requestRef.current) {
          handleOTP(myResend);
          requestRef.current = false;
        }
      }, random_number);
    }
  };
  const cancelBtn = async () => {
    // if (intervalRef.current != null) {
    if (cancelBtnRequestInProgress) {
      return;
    }
    setCancelBtnRequestInProgress(true);
    clearInterval(intervalRef.current);
    setShowTime(false);
    intervalRef.current = null;
    // setRemainingTime(initialTime);
    setResend(false);
    setResendLoading(false);
    handleOTPCancel(mobileId);
    try {
      let res = await CancelOtpAPI({
        serialNumber,
        server,
        mobileId
      }, Token)
      toast({
        title: `Otp request canceled`,
        status: "warning",
        isClosable: true,
      });
      setBalance(res.data.balance);
    } catch (error) {
      handleLogout(error)
    } finally {
      setTimeout(() => {
        setCancelBtnRequestInProgress(false);
      }, 2000);
    }
  };
  useEffect(() => {
    console.log(getInitialTime(time));
    if (remainingTime == 0 && status == 'pending') {
      setTimeout(() => {
        if (getInitialTime(time) <= 0) {
          if (!cancelBtnRequestInProgress) {
            // cancelBtn();
          }
          setShowTime(false);
        }
      }, 2000);
    }
    if (showTime && remainingTime > 0 && status == 'pending') {
      const intervalId = setInterval(() => {
        setRemainingTime(prevTime => prevTime - 1);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [showTime, remainingTime]);
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    }
  }, []);
  return (
    <>
      <Tr className={styles.mytr} textAlign={'center'}>
        <Td
          borderBottom={"1px solid rgb(229 60 94)"}
        >
          {!isLargerThan650 ? <>{time.split(" : ")[0]}<br />{time.split(" : ")[1]}</>
            : time}
          {/* {`${time.split(":")[0]}`} */}
          {/* {time} */}
        </Td>
        <Td
          borderBottom={"1px solid rgb(229 60 94)"}
        >
          {serviceName}
        </Td>
        <Td
          borderBottom={"1px solid rgb(229 60 94)"}
        >
          {price}
        </Td>
        <Td
          borderBottom={"1px solid rgb(229 60 94)"}
        >
          <InputGroup w={['10rem', '10rem', '11.5rem', "12rem"]} m={'auto'}>
            <Input style={isLargerThan650 ? {} : { padding: 0, fontSize: '0.9rem' }} value={mobile} textAlign={['start', 'center', 'center', "center"]} readOnly />
            <InputRightElement>
              <Button
                bg={"white"}
                p={"0 !important"}
                h={"96%"}
                border={"1px solid gray !important"}
                color={"rgb(220, 20, 60)"}
                onClick={CopyToClipBoard}
              >
                <FiCopy />
              </Button>
            </InputRightElement>
          </InputGroup>
        </Td>
        <Td borderBottom={"1px solid rgb(229 60 94)"}
          fontSize={'1.3rem !important'}
        >
          {status == 'pending' ? <GiSandsOfTime style={{ color: '#F2D16B', cursor: 'pointer', margin: 'auto' }} onClick={() => {
            toast({
              status: "info",
              title: `SMS Status is pending`,
              isClosable: true,
              duration: 1000,
            });
          }} /> : status == 'success' ? <BsCheckCircle style={{ color: '#4BB543', cursor: 'pointer', margin: 'auto' }} onClick={() => {
            toast({
              status: "info",
              title: `SMS Status is success`,
              isClosable: true,
              duration: 1000,
            });
          }} /> : <AiOutlineCloseCircle onClick={() => {
            toast({
              status: "info",
              title: `SMS Status is cancelled`,
              isClosable: true,
              duration: 1000,
            });
          }} style={{ color: 'red', cursor: 'pointer', margin: 'auto' }} />}
        </Td>
        <Td
          borderBottom={"1px solid rgb(229 60 94)"}
        >
          <Textarea w={['11rem !important', '12rem !important', '14rem !important', "14rem !important"]} rows={2} readOnly={true} value={otpMessages?.map((el) => {
            return el.otpMessage
          })?.join(" | ")} size={['sm']} />
        </Td>
        <Td borderBottom={"1px solid rgb(229 60 94)"}
        >
          {showTime && <Text
            fontSize={['1rem', '1rem', '1.1rem', "1.2rem"]}
            color={"rgb(220, 20, 60)"}
            fontWeight={600}
            textAlign={"center"}
            mt={"0.8rem !important"}
          >
            {formatTime(remainingTime)}
          </Text>}
        </Td>
        <Td borderBottom={"1px solid rgb(229 60 94)"}
        >
          {
            status == 'pending' ? <Button
              p={['1rem 1.6rem', '1rem 1.8rem', "1.1rem 2rem"]}
              _hover={{
                bgColor: "white",
                color: "rgb(220, 20, 60)",
              }}
              m={'0 !important'}
              w={['4rem', "7.5rem", 'auto', 'auto']}
              fontSize={['0.85rem', '0.9rem', '1rem', "1.1rem"]}
              color={"white"}
              bgColor={"rgb(220, 20, 60)"}
              border={"2px solid rgb(220, 20, 60) !important"}
              onClick={cancelBtn}
              isDisabled={cancelBtnRequestInProgress}
            >
              Cancel
            </Button> : <></>
          }
        </Td>
      </Tr>
    </>
  );
};

export default OtpHistoryCard;