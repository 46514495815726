import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  ModalBody,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  FormControl,
  Input,
  FormErrorMessage,
  InputRightElement,
  Center,
  InputGroup,
  HStack,
  useToast,
  Spinner,
  Select as ChakraSelect,
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import { Formik, Field } from "formik";
import { BiHide, BiShow } from "react-icons/bi";
import Select from "react-select";
import { GetAdminSearchUser, GetAdminSingleUser, GetAllUsers, SetBlock } from "../../../utils/api";
import UserCard from "../components/UserCard";
import Empty from "../../../components/Empty";
const Users = () => {
  const { AdminToken, signupUser } = useContext(AppContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = React.useState(false);
  const [blockModal, setBlockModal] = React.useState(false);
  const [pass, setPass] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [limitNo, setLimitNo] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();
  const [checkUser, setCheckUser] = useState({ isBlock: "", userId: "" });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState({});
  const [searchData, setSearchData] = useState([]);

  let debounceTimer;

  // Define the debounced function
  const debouncedHandleChange = (value) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      GetAdminSearchUser(AdminToken, value).then((res) => {
        setSearchData(res.data.users);
      }).catch((err) => {
        console.log(err, 'error in searching');
      })
    }, 1000); // Adjust the delay time as needed
  };
  const handleSearch = (value) => {
    if (value.length<3) {
      return;
    }
    debouncedHandleChange(value)
  }
  const handleSetBlock = () => {
    SetBlock({ userId: checkUser.userId, block: checkUser.isBlock }, AdminToken)
      .then((res) => {
        toast({
          title: `User successfully ${checkUser.isBlock ? "Unblocked" : "Blocked"
            }`,
          status: "warning",
          isClosable: true,
          duration: 1500,
        });
        if(searched.email) setSearched({ ...searched, isBlock: res.data.status });
        let currentData = data;
        currentData = currentData.map((el) => {
          if (el._id == checkUser.userId) {
            el.isBlock = res.data.status;
          }
          return el;
        });
        setData([...currentData]);
        CloseBlockModal();
      })
      .catch((err) => {
        CloseBlockModal();
        console.log(err);
      });
  };
  const OpenBlockModal = (userId, isBlock) => {
    setBlockModal(true);
    setCheckUser({ isBlock, userId });
  };
  const CloseBlockModal = () => {
    setBlockModal(false);
  };
  useEffect(() => {
    setLoading(false);
    // GetAdminAllUsers(AdminToken).then((res) => {
    //   // console.log(res.data, 'checking my response of all users')
    //   setSearchData(res.data.users);
    // }).catch((err) => {
    //   console.log(err, 'error saying');
    // })
    GetAllUsers(AdminToken, pageNo, limitNo)
      .then((res) => {
        setLoading(true);
        setData(res.data.users);
        console.log(res, "checking response");
        setTotalPages(res.data.total_pages);
      })
      .catch((err) => {
        setLoading(true);
        console.log(err, "error message");
      });
  }, [limitNo, pageNo]);
  const handleChange = (event) => {
    console.log('checking value', event);
    if (event.value != '') {
      GetAdminSingleUser(AdminToken, event.value).then((res) => {
        setSearched(res.data.user);
      }).catch((err) => {
        console.log(err, 'error saying');
      })
    }
  };
  return (
    <VStack
      gap={"1rem"}
      bg={"white"}
      align={"end"}
      justify={"start"}
      height={"100%"}
      minH={"100vh"}
      w={"79%"}
      p={"1.5rem"}
    >
      <Button
        _hover={{
          bgColor: "white",
          color: "rgb(220, 20, 60)",
        }}
        onClick={onOpen}
        color={"white"}
        bgColor={"rgb(220, 20, 60)"}
        border={"2.4px solid rgb(220, 20, 60) !important"}
      >
        Add User
      </Button>
      <VStack align={'self-end'} w={'100%'}>
        <HStack w={'50%'}>
          <Select
            onChange={handleChange}
            onInputChange={handleSearch}
            options={searchData}
            placeholder="Search User Email"
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "rgb(255 227 233 / 63%)",
                primary: "rgb(231 70 102)",
              },
            })}
          />
        </HStack>
        {
          searched._id && <TableContainer
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            w={"100%"}
          >
            <Table
              variant="simple"
              colorScheme="facebook"
              bgColor="white"
              size={["md", "lg", "lg"]}
              overflowX={"auto"}
              boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            >
              <Thead background={"rgb(229 60 94)"} color={"white !important"}>
                <Tr>
                  <Th color={"white"}>Name</Th>
                  <Th color={"white"}>Email</Th>
                  <Th color={"white"}>Balance</Th>
                  <Th color={"white"}>Details</Th>
                  <Th color={"white"}>Block</Th>
                </Tr>
              </Thead>
              <Tbody>
                <UserCard
                  OpenBlockModal={OpenBlockModal}
                  key={searched._id}
                  id={searched._id}
                  CloseBlockModal={CloseBlockModal}
                  isBlock={searched.isBlock}
                  name={searched.name || "user"}
                  email={searched.email}
                  balance={searched.balance || 0}
                />
              </Tbody>
            </Table>
          </TableContainer>
        }
      </VStack>
      {!loading && (
        <VStack justify={"center"} w={"100%"} minH={"50vh"}>
          <Spinner
            thickness="5px"
            speed="0.55s"
            emptyColor="gray.200"
            color="red.400"
            size="xl"
          />
        </VStack>
      )}
      {data.length && loading ? (
        <TableContainer
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          w={"100%"}
        >
          <Table
            variant="simple"
            colorScheme="facebook"
            bgColor="white"
            size={["md", "lg", "lg"]}
            overflowX={"auto"}
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          >
            <Thead background={"rgb(229 60 94)"} color={"white !important"}>
              <Tr>
                <Th color={"white"}>Name</Th>
                <Th color={"white"}>Email</Th>
                <Th color={"white"}>Balance</Th>
                <Th color={"white"}>Details</Th>
                <Th color={"white"}>Block</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((el) => {
                return (
                  <UserCard
                    OpenBlockModal={OpenBlockModal}
                    CloseBlockModal={CloseBlockModal}
                    key={el._id}
                    id={el._id}
                    isBlock={el.isBlock}
                    name={el.name || "user"}
                    email={el.email}
                    balance={el.balance || 0}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      {totalPages > 1 && (
        <HStack gap={"1rem"} align={"center"}>
          <ChakraSelect
            onChange={(e) => {
              setLimitNo(Number(e.target.value));
              setPageNo(1);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={80}>80</option>
            <option value={100}>100</option>
          </ChakraSelect>
          <Button
            onClick={() => {
              setPageNo(1);
            }}
            padding={"0.5rem 1rem"}
            width={"fit-content"}
          >
            First
          </Button>
          <Button
            onClick={() => {
              setPageNo((pre) => {
                if (pre > 1) {
                  return pre - 1;
                } else {
                  return pre;
                }
              });
            }}
          >
            {"<<"}
          </Button>
          <Text
            padding={"0.5rem 1rem"}
            border={"1px solid gray"}
            borderRadius={"5px"}
          >
            {pageNo}
          </Text>
          <Text>/</Text>
          <Text
            padding={"0.5rem 1rem"}
            border={"1px solid gray"}
            borderRadius={"5px"}
          >
            {totalPages}
          </Text>
          <Button
            onClick={() => {
              setPageNo((pre) => {
                if (pre < totalPages) {
                  return pre + 1;
                } else {
                  return pre;
                }
              });
            }}
          >
            {">>"}
          </Button>
          <Button
            onClick={() => {
              setPageNo(totalPages);
            }}
            padding={"0.5rem 1rem"}
            width={"fit-content"}
          >
            Last
          </Button>
        </HStack>
      )}

      {!data.length && loading ? <Empty title={"No user found!!!"} /> : <></>}
      {/* Block User Modal  */}
      <Modal isOpen={blockModal} onClose={CloseBlockModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            minH={"30vh"}
            flexDirection={"column"}
            gap={"1rem"}
            alignItems={"center"}
            pb={6}
          >
            <Text fontSize={"1.4rem"} fontWeight={"600"}>
              Are you sure to {!checkUser.isBlock ? "block" : "unblock"} user!!!
            </Text>
            <HStack>
              <Button
                onClick={() => {
                  handleSetBlock();
                }}
                _hover={{
                  bgColor: "white",
                  color: "rgb(220, 20, 60)",
                }}
                color={"white"}
                bgColor={"rgb(220, 20, 60)"}
                border={"2.4px solid rgb(220, 20, 60) !important"}
              >
                Confirm
              </Button>
              <Button
                color={"rgb(220, 20, 60)"}
                bg={"white"}
                border={"2.4px solid rgb(220, 20, 60) !important"}
                _hover={{
                  bgColor: "rgb(220, 20, 60)",
                  color: "white",
                }}
                onClick={CloseBlockModal}
              >
                Cancel
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Add User Modal  */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            minH={"30vh"}
            gap={"1rem"}
            alignItems={"center"}
            pb={6}
            pt={5}
          >
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={(values) => {
                signupUser(values.email, values.password)
                  .then((res) => {
                    onClose();
                    console.log(res, "inside users.jsx");
                    GetAllUsers(AdminToken)
                      .then((newres) => {
                        setData(newres.data.users);
                      })
                      .catch((err) => {
                        console.log(err, "error message");
                      });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              {({ handleSubmit, errors, touched }) => (
                <form style={{ width: "76.5%" }} onSubmit={handleSubmit}>
                  <VStack p={"1.5rem 0.8rem"} align="flex-start">
                    <FormControl
                      position={"relative"}
                      isInvalid={!!errors.email && touched.email}
                    >
                      <Field
                        as={Input}
                        id="email"
                        name="email"
                        placeholder={"Email"}
                        type="email"
                        variant="outline"
                        border="2px solid black"
                        _hover={{ background: "#ffffff", color: "black" }}
                        validate={(value) => {
                          let error;
                          if (!value) {
                            error = "Required";
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                              value
                            )
                          ) {
                            error = "Invalid email address";
                          }
                          return error;
                        }}
                      />
                      <FormErrorMessage
                        bottom={"-20.5px"}
                        position={"absolute"}
                      >
                        {errors.email}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      marginTop={"1.5rem !important"}
                      isInvalid={!!errors.password && touched.password}
                    >
                      <InputGroup position={"relative"}>
                        <Field
                          as={Input}
                          id="password"
                          placeholder={"Password"}
                          name="password"
                          type={show ? "text" : "password"}
                          border="2px solid black"
                          _hover={{ background: "#ffffff", color: "black" }}
                          validate={(value) => {
                            let error;
                            if (!value) {
                              error = "Required ";
                            } else if (value.length < 7) {
                              error =
                                "Password must contain at least 8 characters";
                            }
                            setPass(value);
                            return error;
                          }}
                        />
                        <InputRightElement width="3rem">
                          <Center
                            fontSize="25px"
                            bg="none"
                            cursor={"pointer"}
                            onClick={() => setShow(!show)}
                          >
                            {show ? (
                              <BiHide style={{ cursor: "pointer" }} />
                            ) : (
                              <BiShow style={{ cursor: "pointer" }} />
                            )}
                          </Center>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage
                        bottom={"-20.5px"}
                        position={"absolute"}
                      >
                        {errors.password}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      marginTop={"1.5rem !important"}
                      isInvalid={!!errors.confirm && touched.confirm}
                    >
                      <InputGroup position={"relative"}>
                        <Field
                          as={Input}
                          id="confirm"
                          name="confirm"
                          placeholder={"Confirm Password"}
                          type={show ? "text" : "password"}
                          border="2px solid black"
                          _hover={{ background: "#ffffff", color: "black" }}
                          validate={(value) => {
                            let error;
                            if (!value) {
                              error = "Required ";
                            } else if (value.length > 5 && pass !== value) {
                              error = "Password must be same";
                            }
                            return error;
                          }}
                        />
                        <InputRightElement width="3rem">
                          <Center
                            fontSize="25px"
                            bg="none"
                            cursor={"pointer"}
                            onClick={() => setShow(!show)}
                          >
                            {show ? (
                              <BiHide style={{ cursor: "pointer" }} />
                            ) : (
                              <BiShow style={{ cursor: "pointer" }} />
                            )}
                          </Center>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage
                        bottom={"-20.5px"}
                        position={"absolute"}
                      >
                        {errors.confirm}
                      </FormErrorMessage>
                    </FormControl>
                    <Button
                      _hover={{
                        color: "rgb(218 52 85)",
                        background: "white",
                      }}
                      type="submit"
                      mt={"2rem !important"}
                      bg="rgb(218 52 85)"
                      color={"white"}
                      border="1.5px solid rgb(218 52 85) !important"
                      width="full"
                      outline={"none"}
                    >
                      Create new user
                    </Button>
                  </VStack>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Users;
