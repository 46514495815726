import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  TableContainer,
  ModalBody,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  Input,
  HStack,
  useToast,
  Spinner,
  Select as ChakraSelect,
  Td,
} from "@chakra-ui/react";
import React, { useContext, useState, useEffect, useRef } from "react";
import { AppContext } from "../../../context/AppContext";
import { EditBalance, GetAdminSearchUser, GetAdminSingleUser, GetAllUsers, GlobalDiscountUpdate, getDiscountServicesUser } from "../../../utils/api";
import Empty from "../../../components/Empty";
import Select from "react-select";
import PaymentCard from "../components/PaymentCard";
const Payment = () => {
  const editBalanceRef = useRef();
  const { AdminToken } = useContext(AppContext);
  const [editModal, setEditModal] = React.useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [limitNo, setLimitNo] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();
  const [userId, setUserId] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleUser, setSingleUser] = useState({});
  const [searchData, setSearchData] = useState([]);
  const [searched, setSearched] = useState({});
  const discountRef1 = useRef();
  const discountRef2 = useRef();
  const discountRef3 = useRef();
  let debounceTimer;

  // Define the debounced function
  const debouncedHandleChange = (value) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      GetAdminSearchUser(AdminToken, value).then((res) => {
        setSearchData(res.data.users);
      }).catch((err) => {
        console.log(err, 'error in searching');
      })
    }, 1000); // Adjust the delay time as needed
  };
  const handleSearch = (value) => {
    if (value.length < 3) {
      return;
    }
    debouncedHandleChange(value)
  }
  const handleEditService = (event) => {
    event.preventDefault();
    let Data = {
      balance: editBalanceRef.current.value,
      id: userId
    };
    EditBalance(AdminToken, Data)
      .then((res) => {
        // console.log(res);
        toast({
          title: "Balance updated successfully!!!",
          isClosable: true,
          status: "success",
        });
        let currentData = data;
        // console.log(searched,'searched');
        if(searched.email)setSearched({ ...searched, balance: Data.balance })
        currentData = currentData.map((el) => {
          if (el._id == userId) {
            el = { ...el, ...Data };
          }
          return el;
        });
        if (currentData.length === 0) {
          setPageNo((pre) => {
            if (pageNo !== 1) {
              return pre - 1;
            }
            return pre;
          });
        }
        setData([...currentData]);
        CloseBlockModal();
      })
      .catch((err) => {
        console.log(err, "error saying");
        toast({
          title: err.response?.data?.message,
          status: "error",
          isClosable: false,
        });
        CloseBlockModal();
      });
  };
  const OpenBlockModal = (userId, el) => {
    setUserId(userId);
    setSingleUser(el);
    setEditModal(true);
  };
  const CloseBlockModal = () => {
    setEditModal(false);
  };
  useEffect(() => {
    getDiscountServicesUser(AdminToken).then((res) => {
      console.log(res.data, 'checking my data')
      discountRef1.current.value = res.data.server1Discount ?? '';
      discountRef2.current.value = res.data.server2Discount ?? '';
      discountRef3.current.value = res.data.server3Discount ?? '';
    }).catch((err) => {
      toast({
        title: err.response?.data?.message
          ? err.response?.data.message
          : err.message,
        isClosable: true,
        status: 'error'
      });
    })
  }, [])
  useEffect(() => {
    setLoading(false);
    GetAllUsers(AdminToken, pageNo, limitNo)
      .then((res) => {
        setLoading(true);
        // console.log(res.data.users);'
        // console.log(res.data);
        setData(res.data.users);
        setTotalPages(res.data.total_pages);
      })
      .catch((err) => {
        setLoading(true);
        console.log(err, "error message");
      });
  }, [limitNo, pageNo]);
  const handleSubmit = (event) => { event.preventDefault() };
  const handleChange = (event) => {
    if (event.value != '') {
      GetAdminSingleUser(AdminToken, event.value).then((res) => {
        setSearched(res.data.user);
      }).catch((err) => {
        console.log(err, 'error saying');
      })
    }
    // console.log(event);
  };
  const handleGlobalDiscount = (server, event) => {
    console.log(event.target.value);
    GlobalDiscountUpdate(AdminToken, { server, discount: event.target.value == '' ? 0 : event.target.value }).then((res) => {
      toast({
        title: "Discount updated successfully",
        isClosable: true,
        duration: 2000,
        status: "success"
      })
    }).catch((err) => {
      console.log(err)
      toast({
        title: err.response?.data?.message
          ? err.response?.data.message
          : err.message,
        isClosable: true,
        status: 'error'
      });

    })
    // EditDiscount(AdminToken, { server, discount: event.target.value == '' ? 0 : event.target.value, userId: id }).then((res) => {
    //   console.log(res.data);
    //   toast({
    //     title: "Discount updated successfully",
    //     isClosable: true,
    //     duration: 2000,
    //     status: "success"
    //   })
    // }).catch((err) => {
    //  console.log(err)
    //   toast({
    //     title: err.response?.data?.message
    //       ? err.response?.data.message
    //       : err.message,
    //     isClosable: true,
    //     status: 'error'
    //   })/
    // })

  }
  return (
    <VStack
      gap={"1rem"}
      bg={"white"}
      align={"end"}
      justify={"start"}
      height={"100%"}
      minH={"100vh"}
      w={"79%"}
      p={"1.5rem"}
    >
      <VStack align={'self-end'} w={'100%'}>
        <HStack w={'50%'}>
          <Select
            onChange={handleChange}
            onInputChange={handleSearch}
            options={searchData}
            placeholder="Search User Email"
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "rgb(255 227 233 / 63%)",
                primary: "rgb(231 70 102)",
              },
            })}
          />
        </HStack>
        {
          searched._id && <TableContainer
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            w={"100%"}
          >
            <Table
              variant="simple"
              colorScheme="facebook"
              bgColor="white"
              size={["md", "lg", "lg"]}
              overflowX={"auto"}
              boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
            >
              <Thead background={"rgb(229 60 94)"} color={"white !important"}>
                <Tr>
                  <Th color={"white"}>Email</Th>
                  <Th color={"white"}>Current Balance</Th>
                  <Th color={"white"}>Edit Balance</Th>
                  <Th color={"white"}>Discount</Th>
                </Tr>
              </Thead>
              <Tbody>
                <PaymentCard
                  OpenBlockModal={OpenBlockModal}
                  CloseBlockModal={CloseBlockModal}
                  key={searched._id}
                  id={searched._id}
                  email={searched.email}
                  discount={searched.discount || []}
                  balance={searched.balance || 0}
                  AdminToken={AdminToken}
                />
              </Tbody>
            </Table>
          </TableContainer>
        }
      </VStack>
      <VStack align={'self-end'} w={'100%'}>
        <HStack w={'50%'}>
          <ChakraSelect onChange={(event) => handleGlobalDiscount('server2', event)} ref={discountRef2} placeholder={"Server 1 discount"}>
            <option value="0">0</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="70">70</option>
            <option value="90">90</option>
          </ChakraSelect>
          <ChakraSelect onChange={(event) => handleGlobalDiscount('server3', event)} ref={discountRef3} placeholder={"Server 2 discount"}>
            <option value="0">0</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="70">70</option>
            <option value="90">90</option>
          </ChakraSelect>
          <ChakraSelect onChange={(event) => handleGlobalDiscount('server1', event)} ref={discountRef1} placeholder={"Server 3 discount"}>
            <option value="0">0</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="70">70</option>
            <option value="90">90</option>
          </ChakraSelect>
        </HStack>
      </VStack>
      {!loading && (
        <VStack justify={"center"} w={"100%"} minH={"50vh"}>
          <Spinner
            thickness="5px"
            speed="0.55s"
            emptyColor="gray.200"
            color="red.400"
            size="xl"
          />
        </VStack>
      )}
      {data.length && loading ? (
        <TableContainer
          boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          w={"100%"}
        >
          <Table
            variant="simple"
            colorScheme="facebook"
            bgColor="white"
            size={["md", "lg", "lg"]}
            overflowX={"auto"}
            boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}
          >
            <Thead background={"rgb(229 60 94)"} color={"white !important"}>
              <Tr>
                <Th color={"white"}>Email</Th>
                <Th color={"white"}>Current Balance</Th>
                <Th color={"white"}>Edit Balance</Th>
                <Th color={"white"}>Discount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.map((el) => {
                return (
                  <PaymentCard
                    OpenBlockModal={OpenBlockModal}
                    CloseBlockModal={CloseBlockModal}
                    key={el._id}
                    id={el._id}
                    email={el.email}
                    balance={el.balance || 0}
                    discount={el.discount || []}
                    AdminToken={AdminToken}
                  />
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      {totalPages > 1 && (
        <HStack gap={"1rem"} align={"center"}>
          <ChakraSelect
            onChange={(e) => {
              setLimitNo(Number(e.target.value));
              setPageNo(1);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={80}>80</option>
            <option value={100}>100</option>
          </ChakraSelect>
          <Button
            onClick={() => {
              setPageNo(1);
            }}
            padding={"0.5rem 1rem"}
            width={"fit-content"}
          >
            First
          </Button>
          <Button
            onClick={() => {
              setPageNo((pre) => {
                if (pre > 1) {
                  return pre - 1;
                } else {
                  return pre;
                }
              });
            }}
          >
            {"<<"}
          </Button>
          <Text
            padding={"0.5rem 1rem"}
            border={"1px solid gray"}
            borderRadius={"5px"}
          >
            {pageNo}
          </Text>
          <Text>/</Text>
          <Text
            padding={"0.5rem 1rem"}
            border={"1px solid gray"}
            borderRadius={"5px"}
          >
            {totalPages}
          </Text>
          <Button
            onClick={() => {
              setPageNo((pre) => {
                if (pre < totalPages) {
                  return pre + 1;
                } else {
                  return pre;
                }
              });
            }}
          >
            {">>"}
          </Button>
          <Button
            onClick={() => {
              setPageNo(totalPages);
            }}
            padding={"0.5rem 1rem"}
            width={"fit-content"}
          >
            Last
          </Button>
        </HStack>
      )}
      {!data.length && loading ? <Empty title={"No user found!!!"} /> : <></>}
      {/* Edit Balance Model */}
      <Modal isOpen={editModal} onClose={CloseBlockModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            display={"flex"}
            justifyContent={"center"}
            minH={"30vh"}
            gap={"1rem"}
            alignItems={"center"}
            pb={6}
            pt={5}
          >
            <form style={{ width: "85.5%" }} onSubmit={handleSubmit}>
              <VStack p={"1.5rem 0"} align="flex-start">
                <Table>
                  <Tbody>
                    <Tr>
                      <Th p={"0.5rem"}>Email</Th>
                      <Td p={"0.5rem"}>{singleUser.email}</Td>
                    </Tr>
                    <Tr>
                      <Th p={"0.5rem"}>Balance</Th>
                      <Td p={"0.5rem"}>{singleUser.balance}</Td>
                    </Tr>
                  </Tbody>
                </Table>
                <Input
                  type={"number"}
                  placeholder="Enter Balance"
                  defaultValue={singleUser.balance}
                  ref={editBalanceRef}
                />
                <Button
                  _hover={{
                    color: "rgb(218 52 85)",
                    background: "white",
                  }}
                  type="submit"
                  mt={"2rem !important"}
                  bg="rgb(218 52 85)"
                  color={"white"}
                  border="1.5px solid rgb(218 52 85) !important"
                  width="full"
                  outline={"none"}
                  onClick={handleEditService}
                >
                  Update Balance
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Payment;
